import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faHeart } from "@fortawesome/free-solid-svg-icons/faHeart";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons/faPhoneSquare";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faStarHalf } from "@fortawesome/free-solid-svg-icons/faStarHalf";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";

library.add(faStar);
library.add(faInfoCircle);
library.add(faStarHalf);
library.add(faTrash);
library.add(faWhatsapp);
library.add(faPhoneSquare);
library.add(faSpinner);
library.add(faInstagram);
library.add(faCheckCircle);
library.add(faPhone);
library.add(faPlus);
library.add(faMinus);
library.add(faFacebookF);
library.add(faEnvelope);
library.add(faShoppingCart);
library.add(faClock);
library.add(faCalendarAlt);
library.add(faHeart);
library.add(faUser);
library.add(faCheck);
library.add(faAngleRight);

dom.watch();
