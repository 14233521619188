import { validateEmail } from "./utils";
import { ajaxPost } from "./utils";

if (window.innerWidth <= 998) {
  window.isMobile = true;
} else {
  window.isMobile = false;
}
$(function () {
  var str = navigator.userAgent;
  var i = str.indexOf("Instagram");
  if (i != -1) {
    swal(
      "Switch Your Browser ⚠",
      "Hello! Please visit orders.thebandracakery.in outside of the Instagram 'in-app browser' to place your order."
    );
  }
  if (!navigator.cookieEnabled) {
    swal(
      "Error",
      "You appear to be running a browser that has cookies disabled. Please enable cookies to ensure that your order can be placed correctly",
      "error"
    );
  }

  var instagramFeed = new Vue({
    el: "#instagram-feed",
    data: {
      images: [],
      followers: 0,
      following: 0,
      posts: 0,
      profile_photo: "",
      bio: "",
      website: "",
    },
    mounted: function () {
      var username = "thebandracakery.in";
      var self = this;

      $("#instagram-feed").on("show.bs.modal", function (e) {
        console.log("TO");
        var myLazyLoad = new LazyLoad();
        myLazyLoad.update();
      });

      $.ajax({
        url: "/api/instagram_data",
        dataType: "json",
        type: "GET",
        success: function (data) {
          self.posts = data.media_count;
          self.website = "https://www.thebandracakery.in";
          self.profile_photo =
            "/instagram-profile.png";
          self.bio =
            "Old school, nostalgic cakes & desserts to make your occasions more momentous";
        },
        error: function (data) {
          console.log(data);
        },
      });

      $.ajax({
        url: "/api/instagram_media",
        type: "GET",
        success: function (data) {
          self.images = data.data;
          var myLazyLoad = new LazyLoad();
          myLazyLoad.update();
        },
        error: function (data) {
          console.log(data);
        },
      });
    },
  });

  var loginApp = new Vue({
    el: "#login-modal",
    data: {
      submitting: false,
      error: "",
      otpSent: false,
      mode: "buttons",
      email: "",
      otp: "",
      phone: "",
      password: "",
      forgotLinks: false,
      confirmPassword: "",
      recovering: false,
    },
    methods: {
      skipLogin() {
        window.location = "/checkout?n=" + new Date().getTime();
      },
      sendOtp() {
        let self = this;
        self.otpSent = true;
        self.submitting = true;
        $.ajax({
          method: "post",
          url: "/api/send_otp",
          dataType: "json",
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          data: { user: { phone: self.phone } },
          success: function (data) {
            self.submitting = false;
          },
          error: function (err) {
            self.error =
              "That phone number does not exist. Please check the number and try again.";
            self.submitting = false;
          },
        });
      },
      otpLogin() {
        this.error = "";
        var self = this;
        this.submitting = true;
        $.ajax({
          method: "post",
          url: "/api/otp_login",
          dataType: "json",
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          data: { user: { email: self.email, otp: self.otp } },
          success: function (data) {
            console.log(data.user);
            try {
              mixpanel.identify("" + data.user.id);
              mixpanel.people.set({
                $email: data.user.email,
                $created: data.user.created_at,
                $last_login: new Date(),
                $first_name: data.user.first_name,
                $last_name: data.user.last_name,
              });
            } catch (e) {
              console.log(e);
            }

            self.submitting = false;
            if (window.redirectLocation) {
              window.location = redirectLocation + "?n=" + new Date().getTime();
            } else {
              window.location.reload();
            }
          },
          error: function (err) {
            self.submitting = false;
            self.error = "Invalid OTP";
          },
        });
      },
      emailForgot() {
        var self = this;
        this.recovering = true;
        $.post("/api/forgot", { email: this.email }, function (data) {
          if (data.status == "ERROR") {
            self.error = "No user with that e-mail address exists.";
            self.recovering = false;
          } else {
            self.otpSent = true;
            self.mode = "otpLogin";
          }
        });
      },
      setMode(m) {
        console.log("Setting Mode");
        this.error = "";
        this.mode = m;
      },
      emailLogin() {
        this.error = "";
        var self = this;
        this.submitting = true;
        $.ajax({
          method: "post",
          url: "/api/login",
          dataType: "json",
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          data: { user: { email: self.email, password: self.password } },
          success: function (data) {
            console.log(data.user);
            try {
              mixpanel.identify("" + data.user.id);
              mixpanel.people.set({
                $email: data.user.email,
                $created: data.user.created_at,
                $last_login: new Date(),
                $first_name: data.user.first_name,
                $last_name: data.user.last_name,
              });
            } catch (e) {
              console.log(e);
            }

            self.submitting = false;
            if (window.redirectLocation) {
              window.location = redirectLocation + "?n=" + new Date().getTime();
            } else {
              window.location.reload();
            }
          },
          error: function (err) {
            self.submitting = false;
            self.error = "Invalid email/password";
          },
        });
      },
      emailRegister() {
        var self = this;
        this.error = "";
        if (this.password != this.confirmPassword) {
          this.error = "Password and confirmation must match.";
          return;
        }
        if (!validateEmail(this.email)) {
          this.error = "Please enter a valid email address.";
          return;
        }
        if (this.password.length < 8) {
          this.error = "Password must be at least 8 characters long.";
          return;
        }
        this.submitting = true;
        ajaxPost({
          url: "/api/new_user",
          data: { user: { email: this.email, password: this.password } },
          dataType: "json",
          success: function () {
            self.submitting = false;
            if (window.redirectLocation) {
              window.location = redirectLocation + "?n=" + new Date().getTime();
            } else {
              window.location.reload();
            }
          },
          error: function () {
            self.submitting = false;
            self.error = "An account with that e-mail address already exists";
            self.forgotLinks = true;
          },
        });
      },
    },
    computed: {
      isMenu() {
        return window.location.href.indexOf("/menu") > -1;
      },
    },
    mounted: function () {
      console.log("******** LOGIN APP ********");
    },
  });

  $(".btn-login").click(function () {
    $("#login-modal").modal("show");
  });
  $(document).on("click", "#google-login", function () {
    $("#login-modal").modal("hide");
    var auth2 = gapi.auth2.init({
      scope: "email profile",
    });
    auth2.signIn().then(function () {
      var auth = auth2.currentUser.get().getAuthResponse();
      $.post("/api/google_login", auth, function (data) {
        if (data.status == "OK") {
          try {
            mixpanel.identify(data.user.id);
            mixpanel.people.set({
              $email: data.user.email,
              $created: data.user.created_at,
              $first_name: data.user.first_name,
              $last_name: data.user.last_name,
            });
          } catch (e) {
            console.log(e);
          }
          if (window.redirectLocation) {
            window.location =
              window.redirectLocation + "?n=" + new Date().getTime();
          } else {
            window.location.reload();
          }
        } else {
          swal("Error", data.message, "error");
        }
      });
    });
  });
  $(document).on("click", "#facebook-login", function () {
    $("#login-modal").modal("hide");
    FB.login(
      function (response) {
        if (response.status == "connected") {
          console.log("Connected");
          $.post("/api/facebook_login", response, function (data) {
            if (data.status == "OK") {
              try {
                mixpanel.identify(data.user.id);
                mixpanel.people.set({
                  $email: data.user.email,
                  $created: data.user.created_at,
                  $first_name: data.user.first_name,
                  $last_name: data.user.last_name,
                });
              } catch (e) {
                console.log(e);
              }
              if (window.redirectLocation) {
                window.location =
                  redirectLocation + "?n=" + new Date().getTime();
              } else {
                window.location.reload();
              }
            } else {
              self.error = data.message;
            }
          });
        } else {
          console.log("Not connected", response);
        }
      },
      {
        scope: "public_profile,email",
      }
    );
  });
});
