export function ajaxPost(options) {
  return $.ajax({
    url: options.url,
    method: "POST",
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "X-CSRF-Token",
        $('meta[name="csrf-token"]').attr("content")
      );
    },
    data: options.data,
    success: options.success,
    error: options.error,
  });
}

export function isBlank(x) {
  return x == null || x == "" || ("" + x).trim() == "";
}

export function validateEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function USDPrice(x) {
  return Math.ceil((x * 1.2) / 70);
}

export function thePrice(x) {
  return x;
}
